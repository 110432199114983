import { gql, useMutation } from "@apollo/client"
import { useLocation } from "@reach/router"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import SEO from "components/SEO"
import Loader from "components/UI/Loader"
import { navigate } from "gatsby"
import { Button } from "nzk-react-components"
import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import config from "../../../../config"
import { CheckoutLayout } from "../../../layouts/CheckoutLayout"

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`

const FormError = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #ec5e2e;
  color: #fff;
  padding: 8px 10px;
  border-radius: 6px;
  text-align: center;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 20px 10px 20px;
  > * {
    margin-bottom: 10px;
  }
  a {
    color: #701ea8;
    text-decoration: underline;
  }
  a.redirect-link {
    font-size: 0.8em;
    margin-top: 20px;
  }
  border-radius: 12px;
  border: 3px solid #701ea8;
  max-width: 400px;
  h1 {
    font-family: "Rammetto One", cursive;
    font-size: 1.8em;
    color: #45106d;
    margin-bottom: 20px;
  }
  text-align: center;
`

const ParentCheckoutComplete = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const redirectTimeoutRef = useRef<any>(null)
  const [timeBeforeRedirect, setTimeBeforeRedirect] = useState(5)
  const [shouldAddPaymentMethod, setShouldAddPaymentMethod] = useState(false)
  const stripe = useStripe()

  const [confirmStripeSubscription, { data: subscriptionData }] = useMutation<
    {
      register_confirmStripeSubscription: {
        status: string
        dashboardUrl: string
        customerId: string
        hash: string
        parent: {
          _id: string
          email: string
          subscription: {
            id: string
            plan: {
              id: string
              interval: string
              interval_count: number
            }
          }
        }
      }
    },
    {
      input: {
        setupIntentId?: string
        paymentIntentId?: string
      }
    }
  >(gql`
    mutation confirmStripeSubscription(
      $input: RegisterConfirmStripeSubscriptionInput!
    ) {
      register_confirmStripeSubscription(input: $input) {
        status
        dashboardUrl
        customerId
        hash
        parent {
          _id
          email
          subscription {
            id
            plan {
              id
              interval
              interval_count
            }
          }
        }
      }
    }
  `)

  const handleRedirect = () => {
    if (redirectTimeoutRef.current) clearInterval(redirectTimeoutRef.current)
    redirectTimeoutRef.current = null
    if (subscriptionData?.register_confirmStripeSubscription.dashboardUrl) {
      window.location.replace(
        subscriptionData.register_confirmStripeSubscription.dashboardUrl
      )
    }
  }

  const confirmSubscription = async () => {
    const params = new URLSearchParams(location.search)
    await confirmStripeSubscription({
      variables: {
        input: {
          paymentIntentId: params.get("payment_intent") || undefined,
          setupIntentId: params.get("setup_intent") || undefined,
        },
      },
      errorPolicy: "all",
    })
    setLoading(false)
  }

  const retrieveErrorCause = async () => {
    const params = new URLSearchParams(location.search)
    if (stripe && params.get("redirect_status") === "failed") {
      if (
        params.get("payment_intent") &&
        params.get("payment_intent_client_secret")
      ) {
        const intent = await stripe.retrievePaymentIntent(
          params.get("payment_intent_client_secret")!
        )
        if (intent?.paymentIntent?.status === "requires_payment_method") {
          setShouldAddPaymentMethod(true)
        }
      } else if (
        params.get("setup_intent_client_secret") &&
        params.get("setup_intent")
      ) {
        const intent = await stripe.retrieveSetupIntent(
          params.get("setup_intent_client_secret")!
        )
        if (intent?.setupIntent?.status === "requires_payment_method") {
          setShouldAddPaymentMethod(true)
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      subscriptionData?.register_confirmStripeSubscription.status ===
        "FAILED" &&
      stripe
    ) {
      retrieveErrorCause()
    }
  }, [stripe, subscriptionData])

  useEffect(() => {
    confirmSubscription()
  }, [])

  const tryAgain = async () => {
    navigate(`/parents/checkout/retry${location.search}`, { replace: true })
  }

  useEffect(() => {
    if (subscriptionData) {
      try {
        if (
          subscriptionData.register_confirmStripeSubscription.status ===
          "FAILED"
        ) {
          return
        }
        const data = subscriptionData.register_confirmStripeSubscription
        const plan = data?.parent.subscription.plan
        let plan_frequency: "yearly" | "monthly" | "quarterly" = "yearly"
        if (plan.interval === "month" && plan.interval_count === 1) {
          plan_frequency = "monthly"
        } else if (plan.interval === "month" && plan.interval_count === 3) {
          plan_frequency = "quarterly"
        }
        // @ts-ignore
        window.dataLayer = window.dataLayer || []
        // @ts-ignore
        window.dataLayer.push({
          event: "ParentCheckout",
          plan_frequency,
          email: data.parent.email,
          userId: data.parent._id,
          customerId: data.customerId,
          stripeCustomerId: data.customerId,
        })

        redirectTimeoutRef.current = setInterval(() => {
          setTimeBeforeRedirect(t => {
            if (t === 0) {
              handleRedirect()
              return t
            }
            return Math.max(0, t - 1)
          })
        }, 1000)
      } catch (err) {
        clearInterval(redirectTimeoutRef.current)
        redirectTimeoutRef.current = null
      }
    }

    return () => {
      clearInterval(redirectTimeoutRef.current)
      redirectTimeoutRef.current = null
    }
  }, [subscriptionData])

  if (loading)
    return (
      <Content>
        <h1>Please wait...</h1>
        <Loader size={40} color="#45106b" />
      </Content>
    )

  if (
    !subscriptionData ||
    subscriptionData.register_confirmStripeSubscription.status === "FAILED"
  ) {
    return (
      <Content>
        <h1>Oops..</h1>
        <p>There was an error processing your subscription.</p>
        {shouldAddPaymentMethod && (
          <>
            <FormError>We failed to verify your payment details.</FormError>
            <p>You can try again by clicking the button below.</p>
            <div>
              <Button theme="purple" size="small" onClick={() => tryAgain()}>
                Try again
              </Button>
            </div>
          </>
        )}
        <p>
          If the problem persists, please contact{" "}
          <a href="mailto:support@nightzookeeper.com">
            support@nightzookeeper.com
          </a>
        </p>
      </Content>
    )
  }

  return (
    <Content>
      <h1>Success</h1>
      <p>
        You will be automatically redirected in {timeBeforeRedirect} seconds.
      </p>
      <a
        className="redirect-link"
        href={`${subscriptionData.register_confirmStripeSubscription.dashboardUrl}`}
      >
        If you are not being redirected, please click here.
      </a>
    </Content>
  )
}

export default () => {
  const stripePromise = useMemo(() => loadStripe(config.stripeKey), [])
  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const clientSecret =
    params.get("payment_intent_client_secret") ||
    params.get("setup_intent_client_secret")

  return (
    <CheckoutLayout>
      <>
        <SEO
          title="Checkout Complete"
          titleTemplate="%s | Night Zookeeper"
          noIndex
        />
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret || undefined,
          }}
        >
          <Wrapper>
            <ParentCheckoutComplete />
          </Wrapper>
        </Elements>
      </>
    </CheckoutLayout>
  )
}
